<template>
    <DataTable :headers="headers"
           :data="items"
           :expandable="expandable"
           :editable="editable"
           :pagination="pagination"
           :title="$t('users.name')"
           type="user" />
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'
import DataTable from '@/components/Table.vue'

export default {
    data() {
        return {
            pagination: {
                sortBy: ['username'],
                rowsPerPage: 25,
            },
            headers: [
                {
                    text: this.$t('users.username'),
                    align: 'left',
                    sortable: true,
                    value: 'username',
                    type: 'string',
                },
                {
                    text: this.$t('users.displayname'),
                    align: 'left',
                    sortable: true,
                    value: 'displayname',
                    type: 'string',
                    editable: true,
                },
                {
                    text: this.$t('users.role'),
                    align: 'left',
                    sortable: true,
                    value: 'role',
                    type: 'string',
                    selectables: [this.$t('Super User'), this.$t('Administrator'), this.$t('Worker'), this.$t('Reader')],
                    repr: (row, value) => [this.$t('Super User'), this.$t('Administrator'), this.$t('Worker'), this.$t('Reader')][value],
                },
                {
                    text: this.$t('users.email'),
                    align: 'left',
                    sortable: true,
                    value: 'email',
                    type: 'mail_split',
                },
                {
                    text: this.$t('users.location'),
                    align: 'left',
                    sortable: true,
                    value: 'location.name',
                    type: 'string',
                    repr: (row, value) => value.toUpperCase(), // value.charAt(0).toUpperCase() + value.slice(1)
                },
                {
                    text: this.$t('users.last_check'),
                    align: 'left',
                    sortable: true,
                    value: 'lastCheck',
                    type: 'date',
                },
                {
                    text: this.$t('users.active'),
                    align: 'left',
                    sortable: true,
                    value: 'enabled',
                    type: 'bool',
                    level: item => {
                        let level = 0
                        if (!item.enabled) {
                            level = 2
                        }
                        return level
                    },
                },
                {
                    text: this.$t('project.name'),
                    align: 'left',
                    sortable: true,
                    value: 'projectName',
                    type: 'link',
                    isList: true,
                    linkIf: item => item.projects !== null && item.projects.length > 0,
                    link: item => item.projects.map(project => ({
                        name: project.name,
                        href: `/project/${project.id}`,
                    })),
                },
            ],
            items: [],
            expandable: false,
            editable: true,
        }
    },

    components: {
        DataTable,
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
        }),

        getRows() {
            const payload = {
                attributes: {
                    include: [],
                    exclude: ['password'],
                },
                includes: [{
                    type: 'project',
                    attributes: ['id', 'name'],
                }],
            }

            rest.getRows('user', payload)
                .then(items => {
                    this.items = items

                    items.forEach(item => {
                        if (item.projects) {
                            item.projectName = item.projects.map(project => project.name).join(', ')
                        }
                    })
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.setNew({
            type: 'user',
            title: 'User',
            modified: (idx, item) => {
                this.items.push(item)

                this.resetNew()
            },
        })

        this.getRows()
    },
}
</script>
